import React from 'react'
import Layout from '../components/Layout'
import Content from '../components/Content'
import PageHero from '../components/PageHero'

const assets = {
  partners: require('../resources/img/hero_orange.jpg'),
}

export default () => (
  <Layout page="partners">
    <div className="clearfix">
      <PageHero title="Partenaires" content={
        'Oeuvrer au développement de l\'e-commerce au Mali ne se fait pas sans partenariats solides.'
      }/>
    </div>
    <div className="clearfix oblique-green">
      <div className="container-fluid" style={{ padding: '0 220px' }}>
        <div className="clearfix" style={{ background: '#000000', padding: '0 120px 0 80px' }}>
          <Content
            image={assets.partners} imageSpan={4} showIcon={false}
            title={'Orange Money'} light={true} paddingTop={80}
            content={
              <div>
                <p>
                  Notre premier et principal partenaire dans cette fantastique expérience est
                  Orange Money, le moyen de paiement mobile de l'opérateur Orange.
                </p>
                <p>
                  L'équipe Orange Money travaille avec Vitepay dans la sécurisation de toutes vos transactions.
                </p>
              </div>
            }
          />
        </div>
      </div>
    </div>
  </Layout>
)
